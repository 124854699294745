import React, { Component } from 'react';
import { JsonToTable } from "react-json-to-table";
import axios from 'axios';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StatusCode: "",
      RouteCode: "",
      errorMessage: "",
      ResponseArry: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const inputValue = event.target.value;
    const stateField = event.target.name;
    this.setState({
      [stateField]: inputValue,
    });
    console.log(this.state);
  }


  async handleSubmit(event) {
    event.preventDefault();
    const { RouteCode } = this.state;
    const baseurl = "https://iksch3m5jj.execute-api.us-east-1.amazonaws.com/Prod/routes/" + RouteCode;
    //axios.get(baseurl).then(response => this.setState({ ResponseArry: response.data, StatusCode: response.status }));
    this.setState({ ResponseArry: "", });
    const response = await axios.get(baseurl);
    this.setState({ ResponseArry: response.data, StatusCode: response.status });

  }


  renderTable() {

    const myJson = this.state.ResponseArry;

    return (
      <div>
        <div>{this.state.RouteCode}</div>
        
        <div>{this.state.errorMessage}</div>
        <div>
          <JsonToTable json={myJson} />

        </div>
      </div>
    );

  }


  renderForm() {

    return (
      <div>
        <div className="App">
          <form onSubmit={this.handleSubmit}>


            <label>Popular Routes:</label>
            <select id="RouteCode" name="RouteCode"

              onChange={this.handleChange}
              value={this.state.RouteCode}
            >
              <option value="NaNRoute" selected> </option>
              <option value="NYCtoATL">NYC to ATL</option>
              <option value="NYCtoDC">NYC to Washington D.C.</option>
            </select>

            <button type="submit">Search</button>
          </form>
        </div>
      </div>

    );


  }


  render() {



    return (
      <div >
        {this.renderForm()}
        {this.renderTable()}

      </div>

    );
  }
}
